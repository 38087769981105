import Actions from "modules/actions";

import PayorAccountActions from "store/actions/payor-account";
import NpiActions from "store/actions/npi";
import PhysicianActions from "store/actions/physician";
import BillingClientContractualInformationActions from "store/actions/billing-client-contractual-information";
import StateMedicalLicenseActions from "store/actions/state-medical-license";
import EmailActions from "store/actions/email";
import ContactInformationActions from "store/actions/contact-information";
import SampleStorageLaboratoryActions from "store/actions/sample-storage-laboratory";

const payorAccountActions = new PayorAccountActions();
const physicianActions = new PhysicianActions();
const npiActions = new NpiActions();
const bcciActions = new BillingClientContractualInformationActions();
const stateMedicalLicenseActions = new StateMedicalLicenseActions();
const emailActions = new EmailActions();
const contactInformationActions = new ContactInformationActions();
const sampleStorageLaboratoryActions = new SampleStorageLaboratoryActions();
export default class ClientAccountsActions extends Actions {
  async getExclusions(filter = null) {
    return await payorAccountActions.getExclusions(filter);
  }

  async searchPayorAccount(params) {
    return await payorAccountActions.searchPayorAccount(params);
  }

  async createPayorAccount(params) {
    return await payorAccountActions.create(params);
  }

  async updatePayorAccount(params) {
    return await payorAccountActions.update(params);
  }

  async getPayorAccount(params) {
    return await payorAccountActions.get(params);
  }

  async getClientInvoicing(params){
    return await payorAccountActions.getClientInvoicing(params)
  }

  async searchNpi(npi) {
    return await npiActions.searchNpi(npi);
  }

  async createNpi(params) {
    return await npiActions.create(params);
  }

  async getPhysicians(params) {
    return await physicianActions.getPhysicianList(params);
  }

  async createPhysician(params) {
    return await physicianActions.create(params);
  }

  async updatePhysician(params) {
    return await physicianActions.update(params);
  }

  async createBCCI(params) {
    return await bcciActions.create(params);
  }

  async updateBCCI(params) {
    return await bcciActions.update(params);
  }

  async archiveBCCI(params) {
    return await bcciActions.archive(params);
  }

  async deArchiveBCCI(params) {
    return await bcciActions.deArchive(params);
  }

  async searchBCCI(params) {
    return await bcciActions.search(params);
  }

  async getPaymentTypes(params) {
    return await bcciActions.getPaymentTypes(params);
  }

  async getPaymentTerms(params) {
    return await bcciActions.getPaymentTerms(params);
  }

  async createStateMedicalLicense(params) {
    return await stateMedicalLicenseActions.create(params);
  }

  async createEmail(params) {
    return await emailActions.create(params);
  }

  async getContactTypes(filter = null) {
    return await contactInformationActions.getContactTypes(filter);
  }

  async createContactInformation(params) {
    return await contactInformationActions.create(params);
  }

  async updateContactInformation(params) {
    return await contactInformationActions.update(params);
  }

  async archiveContactInformation(params) {
    return await contactInformationActions.archive(params);
  }

  async deArchiveContactInformation(params) {
    return await contactInformationActions.deArchive(params);
  }

  async getSampleStorageLaboratories(params) {
    return await sampleStorageLaboratoryActions.all(params);
  }

  async activatePayorAccount(params) {
    return await payorAccountActions.activate(params);
  }

  async deactivatePayorAccount(params) {
    return await payorAccountActions.deactivate(params);
  }
}
