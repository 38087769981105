import ContactInformation from "store/models/ContactInformation";
import ContactType from "store/models/ContactType";

import StoreActions from "store/actions";

const storeActions = new StoreActions(ContactInformation);
const contactTypeActions = new StoreActions(ContactType);
export default class ContactInformationActions {

  async getContactTypes(variables) {
    return await contactTypeActions.query(
      {
        queryName: "contactTypes",
        relativeUrl: "/contact-information/contact-types",
        ...variables,
      },
      "to load lists of contact types."
    );
  }

  async create(params) {
    const { queries, variables, collectionQueries } = params;

    return await storeActions.mutation(
      {
        queryName: "createContactInformation",
        relativeUrl: "/contact-information/create-contact-information",
        queries,
        collectionQueries: collectionQueries || [],
      },
      "To create contact information.",
      { ...variables }
    );
  }

  async update(params) {
    const { queries, variables, collectionQueries } = params;

    return await storeActions.mutation(
      {
        queryName: "updateContactInformationDetails",
        relativeUrl: "/contact-information/update-contact-information",
        queries,
        collectionQueries: collectionQueries || [],
      },
      "To update contact information.",
      { 
        contactInformationId: variables.contactInformationId
      }
    );
  }

  async archive(params) {
    const { id } = params;

    return await storeActions.mutation(
      {
        queryName: "archiveContactInformation",
        relativeUrl: "/contact-information/archive-contact-information",
        noMutationString: true,
      },
      "To archive contact information.",
      { id }
    );
  }

  async deArchive(params) {
    const { id } = params;

    return await storeActions.mutation(
      {
        queryName: "deArchiveContactInformation",
        relativeUrl: "/contact-information/de-archive-contact-information",
        noMutationString: true,
      },
      "To de-archive contact information.",
      { id }
    );
  }
}
