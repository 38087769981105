import SampleStorageLaboratory from "store/models/SampleStorageLaboratory";

import StoreActions from "store/actions";

const storeActions = new StoreActions(SampleStorageLaboratory);
export default class SampleStorageLaboratoryActions {
  async all(params = null) {
    const { filter, limitData, order } = params;

    return await storeActions.query(
      {
        queryName: "sampleStorageLaboratories",
        relativeUrl: "/sample-storage-laboratory/get-all-storage",
        filter: filter || null,
        limitData: limitData || null,
        order: order || null,
      },
      "To get all sample storage laboratories."
    );
  }
}
