import BillingClientContractualInformation from "store/models/BillingClientContractualInformation";

import StoreActions from "store/actions";

const storeActions = new StoreActions(BillingClientContractualInformation);
export default class BillingClientContractualInformationActions {
  async create({variables, queries}) {
    return await storeActions.mutation(
      {
        queryName: "createBillingClientContractualInformation",
        relativeUrl: "/bcci/create-bcci",
        queries,
      },
      "To create billing client contractual information.",
      {...variables}
    );
  }

  async update(params) {
    const { variables, queries, collectionQueries } = params;
    return await storeActions.mutation(
      {
        queryName: "updateBillingClientContractualInformationDetails",
        relativeUrl: "/bcci/update-bcci",
        queries,
        collectionQueries
      },
      "To update billing client contractual information.",
      { ...variables }
    );
  }

  async archive(params) {
    const { billingClientContractualInformationId } = params;

    return await storeActions.mutation(
      {
        queryName: "archiveBillingClientContractualInformation",
        relativeUrl: "/bcci/archive-bcci",
        noMutationString: true,
      },
      "To archive contractual information.",
      { billingClientContractualInformationId }
    );
  }

  async deArchive(params) {
    const { billingClientContractualInformationId } = params;

    return await storeActions.mutation(
      {
        queryName: "deArchiveBillingClientContractualInformation",
        relativeUrl: "/bcci/de-archive-bcci",
        noMutationString: true,
      },
      "To de-archive contractual information.",
      { billingClientContractualInformationId }
    );
  }

  async search(params) {
    const { limitData, filter } = params;

    return await storeActions.query(
      {
        queryName: "billingClientContractualInformations",
        relativeUrl: "/bcci/search-bcci",
        limitData: limitData || null,
        filter: filter || null,
      },
      "To search billing client contractual informations."
    );
  }

  async getPaymentTypes(variables) {
    return await storeActions.query(
      {
        queryName: "vbsPaymentTypes",
        relativeUrl: "/payment-types",
        ...variables,
      },
      "To get the list of payment type."
    );
  }

  async getPaymentTerms(variables) {
    return await storeActions.query(
      {
        queryName: "vbsPaymentTerms",
        relativeUrl: "/payment-terms",
        ...variables,
      },
      "To get the list of payment terms"
    );
  }
}
